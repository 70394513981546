import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";
import Col from "../../components/styled/grid/Col";
import {Body, Title} from "../../components/styled/typography/Typography";
import {ButtonContainer, ButtonLink} from "../../components/styled/button/ButtonComponents";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const ThankYou = ({data}) => {

    const {demo} = data

    return (
        <Layout lang={demo.locale} seo={{title: demo.locale === "hu" ? "Üzenet elküldve" : "Message sent", isIndexable: false}}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid>
                <Col grid center>
                    <Title color="white" textAlign="center" marginBottom="normal">
                        {demo.thank_you_section.title}
                    </Title>
                    <Body color="body-text" textAlign="center" marginBottom="small">
                        {demo.thank_you_section.subtitle}
                    </Body>
                    <Body color="body-text" textAlign="center" bold>
                        {demo.thank_you_section.subtitle_bold}
                    </Body>
                    <Col grid center>
                        <ButtonContainer>
                            <ButtonLink to={demo.thank_you_section.button.link} color="primary-brand">
                                {demo.thank_you_section.button.name}
                            </ButtonLink>
                        </ButtonContainer>
                    </Col>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleThankYou($locale: String) {
       demo: strapiDemoForm(locale: {eq: $locale}) {
        locale
        thank_you_section {
            title
            subtitle_bold
            subtitle
            button {
              name
              link
            }
        }
     }
   }
 `

export default ThankYou